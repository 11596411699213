.commission-page {
    padding: 16px 0 16px 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.commission-page-content {
    width: 70% !important;
}